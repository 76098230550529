import { render, staticRenderFns } from "./error.vue?vue&type=template&id=5c09efde&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=5c09efde&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c09efde",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VApp: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VApp/VApp.js').default})
