






















import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    size: {
      type: String,
      required: true
    },
  },
})
