import { render, staticRenderFns } from "./ecMarquee.vue?vue&type=template&id=c08c3b82&scoped=true&lang=pug&"
import script from "./ecMarquee.vue?vue&type=script&lang=ts&"
export * from "./ecMarquee.vue?vue&type=script&lang=ts&"
import style0 from "./ecMarquee.vue?vue&type=style&index=0&id=c08c3b82&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c08c3b82",
  null
  
)

export default component.exports