//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent,
  ref,
  useContext,
  computed,
  useStatic,
  onMounted,
} from '@nuxtjs/composition-api'

import copy from '~/helpers/copy'
import { get } from '~/helpers/fetch'

export default defineComponent({
  setup(_, ctx) {
    const drawer = ref(false)
    const btt = ref(false)

    const { isDev, $content } = useContext()

    // could be optimized
    const losungen = ref(null)

    onMounted(() => {
      const RIC = ('requestIdleCallback' in window) ? requestIdleCallback : (cb) => setTimeout(cb, 5000)
      
      RIC(() => {
        const n = new Date()
        const today = `${n.getFullYear()}-${n.getMonth() + 1 < 10
            ? '0' + (n.getMonth() + 1)
            : n.getMonth() + 1
        }-${
          n.getDate() < 10
            ? '0' + n.getDate()
            : n.getDate()
        }`

        get(`https://losungen.ec-nordbund.de/${today}.json`).then(
          (v) => (losungen.value = v)
        )
      })
    })

    const isStartPage = computed(() => ctx.root.$nuxt.$route.path == '/')

    const losung = computed(
      () =>
        losungen.value &&
        losungen.value.Losungstext
          .split('/')
          .join('<b><i>')
          .split(':<b><i>')
          .join(':</i></b>')
          .split('#')
          .map((v, i) => {
            if (i === 0) {
              return v
            }

            if (i % 2 === 0) {
              return '</i>' + v
            } else {
              return '<i>' + v
            }
          })
          .join('')
    )
    const lehrtext = computed(
      () =>
        losungen.value &&
        losungen.value.Lehrtext
          .split('/')
          .join('<i>')
          .split(':<i>')
          .join(':</i>')
          .split('#')
          .map((v, i) => {
            if (i === 0) {
              return v
            }

            if (i % 2 === 0) {
              return '</i>' + v
            } else {
              return '<i>' + v
            }
          })
          .join('')
    )
    const marqueeContentLength = computed(
      () =>
        losungen.value &&
        losungen.value.Losungstext.length +
          losungen.value.Losungsvers.length +
          losungen.value.Lehrtext.length +
          losungen.value.Lehrtextvers.length +
          140
    )

    function onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      btt.value = top > 128
    }

    return {
      losungen,
      btt,
      onScroll,
      losung,
      lehrtext,
      marqueeContentLength,
      drawer,
      copy2clip: copy,
      isDev,
      isStartPage,
    }
  },
})
