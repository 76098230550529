





import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'none',
    },
    length: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    return {props}
  }
})
