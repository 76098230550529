import { render, staticRenderFns } from "./minimal.vue?vue&type=template&id=cc1dcaee&scoped=true&lang=pug&"
var script = {}
import style0 from "./minimal.vue?vue&type=style&index=0&id=cc1dcaee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc1dcaee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EcLogo: require('/home/runner/work/EC-Website/EC-Website/components/ecLogo.vue').default,VAppBar: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VAppBar/VAppBar.js').default,VMain: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VMain/VMain.js').default,VCol: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VGrid/VCol.js').default,VRow: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VGrid/VRow.js').default,VContainer: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VGrid/VContainer.js').default,VApp: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VApp/VApp.js').default})
